import React from "react";
import C from "classnames";

import style from "./index.module.styl";

interface IColumnProps {
  readonly children: React.ReactNode;
  readonly className?: string;
}

export default function CenterColumn({ children, className }: IColumnProps) {
  return (
    <div className={C(style.column, className)}>
      <div className={style.columnContent}>{children}</div>
    </div>
  );
}
