import React from "react";

import { ROUTES } from "../../../common/routes.js";
import { useGlobalStore, IGlobalStateSitemapPage } from "../../../store";

import style from "./index.module.styl";
import page from "..";
import GeneralLayout from "../../layouts/general";
import CenterColumn from "../../centerColumn";

import Header from "../../header";

interface ISitemapPageProps {
  readonly page: IGlobalStateSitemapPage;
}

function SitemapPage({ page }: ISitemapPageProps) {
  const { makeLink } = useGlobalStore();
  return !page.hiddenFromSitemap ? (
    <li>
      <a href={page.link == "/" ? makeLink(ROUTES.home()) : page.link}>
        {page.title}
      </a>
      {!!page.pages && (
        <ul>
          {page.pages.map((x, i) => (
            <SitemapPage key={i} page={x} />
          ))}
        </ul>
      )}
    </li>
  ) : null;
}

function Sitemap() {
  const { breadcrumbs, sitemap, i18n} = useGlobalStore();

  return (
    <GeneralLayout breadcrumbs={breadcrumbs}>
      <CenterColumn>
        <Header
          titleDesktop={i18n.t("sitemap_title")}
          titleMobile={i18n.t("sitemap_title")}
        />
        <div className={style.sitemapContent}>
          <ul>
            {sitemap.map(x => (
              <SitemapPage page={x} />
            ))}
          </ul>
        </div>
      </CenterColumn>
    </GeneralLayout>
  );
}

export default page(Sitemap);
