import React from "react";
import C from "classnames";

import style from "./index.module.styl";

export enum HeaderMargin {
  normal = "normal",
  small = "small",
  reallySmall = "reallySmall",
}

interface IHeaderProps {
  readonly titleDesktop: string;
  readonly titleMobile: string;
  readonly className?: string;
  readonly margin?: HeaderMargin;
  readonly onlyDesktop?: boolean;
}

export default function Header({
  titleDesktop,
  titleMobile,
  className,
  onlyDesktop = false,
  margin = HeaderMargin.normal,
}: IHeaderProps) {
  return (
    <div
      className={C(style.header, className, {
        [style.headerMarginNormal]: margin === HeaderMargin.normal,
        [style.headerMarginSmall]: margin === HeaderMargin.small,
        [style.headerMarginReallySmall]: margin === HeaderMargin.reallySmall,
        [style.headerOnlyDesktop]: onlyDesktop,
      })}
    >
      <h1
        className={C(style.headerTitle, style.desktop)}
        dangerouslySetInnerHTML={{ __html: titleDesktop }}
      />
      <h1
        className={C(style.headerTitle, style.mobile)}
        dangerouslySetInnerHTML={{ __html: titleMobile }}
      />
    </div>
  );
}
